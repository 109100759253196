import React from "react";
// import Shop from "../../src/Components/Shop/Shop";
import Product from "../Components/Shop/Product";
import ShopBanner from "../Components/Shop/ShopBanner";
function Shoppage() {
  return (
    <>
      {/* <Shop /> */}
      <ShopBanner />
      {/* <Search /> */}
      {/* <Categories /> */}
      <Product />
    </>
  );
}

export default Shoppage;
